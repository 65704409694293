<template>
  <b-overlay
    :show="!templates"
    rounded="sm"
  >
    <b-card>
      <div class="invoice-register-table pt-1">
        <div class="float-left ir-btn-wrapper">
          <b-button
            v-b-modal.add-template-modal
            size="md"
            variant="primary"
          >
            Add Template
          </b-button>&nbsp;
        </div>
        <!-- search input -->
        <div
          class="custom-search d-flex justify-content-end"
        >
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block closed-box2"
                @keydown="searchTable"
              />
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          v-if="templates"
          :columns="columns"
          :rows="templates"
          :sort-options="{
            enabled: true,
          }"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-2"

          mode="remote"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
        >
          <template slot="loadingContent">
            <div
              class="my-1 d-flex spinner-border text-primary justify-content-center"
              style="width: 2.5rem; height: 2.5rem; margin: auto;"
              role="status"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              {{ props.row.id }}
            </span>

            <!-- Column: Template -->
            <span
              v-else-if="props.column.field === 'template'"
              v-html="props.row.template"
            />

            <span v-else-if="props.column.field === 'created_at'">
              {{ filterFormatDate(props.row.created_at) }}
            </span>

            <span v-else-if="props.column.field === 'action'">
              <b-dropdown
                id="dropdown-1"
                text="Action"
              >
                <b-dropdown-item @click="editTemplate(props.row)">Edit</b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item @click="deleteTemplate(props.row)">Delete</b-dropdown-item>
              </b-dropdown>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','25','50']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      <!-- Add Template Modal -->
      <b-modal
        id="add-template-modal"
        ref="add-modal"
        ok-title="Submit"
        cancel-title="Cancel"
        centered
        size="lg"
        title="Add Response Template"
        @ok="handleApply"
      >
        <validation-observer ref="rules">
          <b-form
            ref="form"
            @submit.prevent
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="title"
                  :state="errors.length > 0 ? false:null"
                  :disabled="btnSending"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Response Template"
              description="Note: To add a variable, use the format {variable}. For example, {name} will be replaced with the name of the user."
              label-for="template"
            >
              <validation-provider
                #default="{ errors }"
                name="Response Template"
                rules="required"
              >
                <quill-editor
                  id="template"
                  v-model="template"
                  :options="snowOption"
                  :state="errors.length > 0 ? false:null"
                  :disabled="btnSending"
                  @change="onTemplateChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <small
              v-if="templateForms.length > 0"
              class="text-info"
            >Variables: {{ templateForms.join(', ') }} </small>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- Edit Template Modal -->
      <b-modal
        id="edit-template-modal"
        ref="edit-modal"
        ok-title="Submit"
        cancel-title="Cancel"
        centered
        size="lg"
        title="Edit Response Template"
        @ok="handleApplyEdit"
      >
        <validation-observer ref="rules2">
          <b-form
            ref="form"
            @submit.prevent
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="titleEdit"
                  :state="errors.length > 0 ? false:null"
                  :disabled="btnSending"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Response Template"
              description="Note: To add a variable, use the format {variable}. For example, {name} will be replaced with the name of the user."
              label-for="template"
            >
              <validation-provider
                #default="{ errors }"
                name="Response Template"
                rules="required"
              >
                <quill-editor
                  id="template"
                  v-model="templateEdit"
                  :options="snowOption"
                  :state="errors.length > 0 ? false:null"
                  :disabled="btnSending"
                  @change="onTemplateChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <small
              v-if="templateForms.length > 0"
              class="text-info"
            >Variables: {{ templateForms.join(', ') }} </small>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BPagination, BFormSelect, BCard, BFormGroup, BFormInput, BButton, BModal, BForm, BOverlay, BDropdown, BDropdownItem, BDropdownDivider,
  // BCol, BRow, BForm,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import axios from '@/libs/axios'

export default {
  components: {
    BOverlay,
    BForm,
    quillEditor,
    BButton,
    VueGoodTable,
    BPagination,
    BCard,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BModal,
    ValidationObserver,
    ValidationProvider,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      searchTerm: '',
      templates: null,
      snowOption: {
        theme: 'snow',
      },
      template: '',
      templateForms: [],
      title: '',
      templateEdit: '',
      titleEdit: '',
      idEdit: '',
      isLoading: false,
      btnSending: false,
      totalRecords: 0,
      serverParams: {
        search: '',
        columnFilters: [{
          // column: 'invoice_type',
          // filter: 'romania',
        }],
        sort: [
          {
            field: 'id',
            type: 'desc',
          },
        ],
        page: 1,
        perPage: 25,
      },
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
    columns() {
      const columns = [
        {
          label: '#',
          field: 'id',
          tdClass: 'w-10',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Template',
          field: 'template',
        },
        {
          label: 'Created',
          field: 'created_at',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ]
      return columns
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    filterFormatDate(date) {
      return moment(date).format('DD MMM YYYY')
    },
    searchTable() {
      this.category = 'All'
      this.searchTerm = this.searchTermTxt.trim()
    },
    async handleApply(bvModalEvent) {
      bvModalEvent.preventDefault()
      await this.$refs.rules.validate().then(success => {
        if (success) {
          this.sending = true
          this.btnSending = true
          axios.post('/api/tickets/template/add', {
            template: this.template,
            title: this.title,
          })
            .then(() => {
              this.notify({
                text: 'Template added successfully',
                variant: 'success',
                timeout: 2000,
              })
              this.$refs['add-modal'].hide()
              this.btnSending = false
              this.template = ''
              this.title = ''
              this.loadItems()
            })
            .catch(error => { console.log(error) })
        }
      })
    },

    async handleApplyEdit(bvModalEvent) {
      bvModalEvent.preventDefault()
      await this.$refs.rules2.validate().then(success => {
        if (success) {
          this.sending = true
          this.btnSending = true
          axios.post(`/api/tickets/template/edit/${this.idEdit}`, {
            template: this.templateEdit,
            title: this.titleEdit,
          })
            .then(() => {
              this.notify({
                text: 'Template updated successfully',
                variant: 'success',
                timeout: 2000,
              })
              this.$refs['edit-modal'].hide()
              this.btnSending = false
              this.loadItems()
              this.templateEdit = ''
              this.titleEdit = ''
              this.idEdit = null
            })
            .catch(error => { console.log(error) })
        }
      })
    },

    editTemplate(data) {
      this.templateForms = []
      this.idEdit = data.id
      this.templateEdit = data.template
      this.titleEdit = data.title
      this.$refs['edit-modal'].show()
    },

    deleteTemplate(data) {
      this.$swal({
        html: `Do you want to delete this template <b>${data.title}</b>?`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary mr-1',
          cancelButton: 'btn btn-secondary',
        },
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        buttonsStyling: false,
      }).then(res => {
        if (res.isConfirmed) {
          this.$store.dispatch('tickets/deleteTemplate', data.id)
            .then(() => {
              this.loadItems()
              this.notify({
                text: 'Template deleted successfully',
                variant: 'success',
                timeout: 2000,
              })
            })
            .catch(error => { console.log(error) })
        }
      })
    },

    onTemplateChange() {
      let str = ''
      if (this.template !== '') {
        str = this.template
      }
      if (this.templateEdit !== '') {
        str = this.templateEdit
      }
      const pattern = /\{(\w+)\}/g

      const matches = [...str.matchAll(pattern)]
      const forms = matches.map(match => match[1])

      this.templateForms = forms
    },

    // vue good table
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1, perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    onSearch() {
      this.updateParams({ page: 1, perPage: this.serverParams.perPage, search: this.searchTerm })
      this.loadItems()
    },
    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true
      this.$store.dispatch('tickets/fetchTemplates', this.serverParams)
        .then(response => {
          this.totalRecords = response.total
          this.templates = response.data
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

.mx-datepicker {
  width: 100% !important;
}

.invoice-register-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 30rem;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}

.closed-box1{
  width: 16rem
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .mr-1{
    display: none;
  }
  .closed-box1 {
    width: 84% !important
  }
  .closed-box2{
    float: left !important;
    margin-top: 5px;
    margin-bottom: -15px;
    width: 428px;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {
  .closed-box1 {
    width: 80% !important
  }
  .closed-box2{
    width: 428px;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {

  .closed-box1 {
    width: 74% !important
  }
  .closed-box2{
    width: 428px;
  }

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {
  .closed-box1 {
    width: 71% !important
  }
  .closed-box2{
    width: 428px;
  }
}

/* smaller phone */
@media only screen and (max-width: 365px)  {
  .closed-box1 {
    width: 68% !important
  }
  .closed-box2{
    width: 428px;
  }
}

.modal .modal-header .close{
  margin: 2px
}

.ql-editor {
  min-height: 320px;
}

.dark-layout a {
  color: #fb50a1 !important;
}
</style>
